// General
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Helpers
import { useGA, useInitGA } from '../../hooks/useGA';

export const GoogleAnalytics = () => {
  const initGA = useInitGA();
  const location = useLocation();
  const ga = useGA();

  useEffect(() => {
    ga.trackPageView(`${window.location.hostname}${window.location.pathname}`);
  }, [location, ga]);

  useEffect(() => {
    initGA(true); // enable google analytics

    return () => {
      initGA(false); // disable google analytics
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

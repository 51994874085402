import styled from 'styled-components';
import Mooncourt from '../../icons/Mooncourt';
import BallIcon from '../../icons/BallIcon';
import { FontFamilies } from '../../../styles/FontFamilies';
import { BREAKPOINT_XXL } from '../../../styles/Breakpoints';
import { Button } from '../../common/Button';
import LinkHandler from 'src/components/common/LinkHandler';

const Wrapper = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  border-radius: 1.25rem;
  border: 1px solid #fff;
  background: var(--color-black-900);
  padding: 2rem 3.75rem;
  backdrop-filter: blur(6px);

  ${BREAKPOINT_XXL} {
    grid-column: 13 / 21;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  svg {
    font-size: 1.5rem;
    color: var(--color-primary-750);
  }
`;

const Headline = styled.h4`
  font-family: ${FontFamilies.headline};
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
`;

const BenefitsWrapper = styled.div`
  display: grid;
  gap: 1rem;
`;

const Benefit = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: ${FontFamilies.headline};
  font-size: 1.25rem;
  line-height: 1.25;

  svg {
    flex-shrink: 0;
    font-size: 1.5rem;
  }
`;

const StyledLinkHandler = styled(LinkHandler)`
  align-self: center;

  :hover {
    text-decoration: none;
  }
`;

const LoginButton = styled(Button)`
  align-self: center;
  width: 10rem;
  justify-content: center;
`;

export default function MembershipCTA() {
  return (
    <Wrapper>
      <Header>
        <Mooncourt />
        <Headline>Join the Mooncourt</Headline>
      </Header>

      <BenefitsWrapper>
        <Benefit>
          <BallIcon />
          <p>Begin your journey for free—explore the store, connect with the community, and start earning daily Moonpoints.</p>
        </Benefit>

        <Benefit>
          <BallIcon /> <p>Unlock exclusive sneaker releases, raffles, and giveaways by upgrading to one of our memberships.</p>
        </Benefit>

        <Benefit>
          <BallIcon /> <p>Boost your raffle chances by earning Moonpoints through interactions, Baller Training, and starter bonuses.</p>
        </Benefit>
      </BenefitsWrapper>

      <StyledLinkHandler to={'/membership'}>
        <LoginButton>{'Get Started'}</LoginButton>
      </StyledLinkHandler>
    </Wrapper>
  );
}

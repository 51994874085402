import styled from 'styled-components';
import Modal from '../common/Modal';
import Logo from '../icons/Logo';
import Wysiwyg from '../common/Wysiwyg';
import { Button } from '../common/Button';
import { BREAKPOINT_XS } from '../../styles/Breakpoints';

const StyledLogo = styled(Logo)`
  font-size: 3.5rem;
  margin-block-end: 1.25rem;
  margin-inline: auto;
  display: block;
`;

const ModalContent = styled(Wysiwyg)`
  font-size: 1rem;
  margin-block-end: 1.5rem;

  ${BREAKPOINT_XS} {
    margin-block-end: 3rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.875rem;
  justify-content: center;
  flex-direction: column;

  ${BREAKPOINT_XS} {
    flex-direction: row;
  }
`;

const ModalButton = styled(Button)`
  padding-inline: 2.5rem;
  width: 100%;
  justify-content: center;

  ${BREAKPOINT_XS} {
    width: auto;
  }
`;

interface ITermsModalProps {
  close: () => void;
  content: string;
  onAccept: () => void;
  acceptText: string;
}

export default function TermsModal(props: ITermsModalProps) {
  const { close, content, onAccept, acceptText } = props;

  return (
    <Modal close={close}>
      <StyledLogo />

      <ModalContent content={content} />

      <ButtonWrapper>
        <ModalButton theme='secondary' onClick={close}>
          Cancel
        </ModalButton>

        <ModalButton theme='primary' onClick={onAccept}>
          {acceptText}
        </ModalButton>
      </ButtonWrapper>
    </Modal>
  );
}

import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useApi, useIsApiReady } from 'src/services/ApiProvider';
import LoadingAnimation from 'src/components/common/LoadingAnimation';
import useToast from 'src/hooks/useToast';
import { useRecoilValue } from 'recoil';
import { AccountState } from 'src/states/AppData';
import LoginModal from 'src/components/content/user/LoginModal';
import LoginCTA from 'src/components/content/subscription/LoginCTA';
import { Translations } from 'src/utils/Translations';
import LinkHandler from 'src/components/common/LinkHandler';
import { Button } from 'src/components/common/Button';

const LoadingWrapper = styled.div`
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-self: center;
`;

const StatusWrapper = styled.div`
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-self: center;
`;

const StyledLoadingAnimation = styled(LoadingAnimation)`
  svg {
    width: 2rem;
    height: 2rem;
  }
`;

const Headline = styled.h3`
  font-size: 1.875rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
`;

const ResultText = styled.p`
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  color: var(--color-text-default);
  margin-block-end: 2rem;
`;

const ResultHeadline = styled(Headline)<{ color: string }>`
  color: var(--color-signal-${(props) => props.color});
`;

const Wrapper = styled.div`
  padding: 2rem;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-block-start: 2.5rem;
  height: 60vh;
`;

const StyledLinkHandler = styled(LinkHandler)`
  align-self: center;

  :hover {
    text-decoration: none;
  }
`;

export default function Redeem() {
  const params = useParams();
  const { code } = params;
  const apiInstance = useApi();
  const isApiReady = useIsApiReady();
  const accountState = useRecoilValue(AccountState);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [result, setResult] = useState<any>(null);
  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);

  async function redeemCode(code: string) {
    try {
      setIsLoading(true);
      const res = (await apiInstance.post(`/v2/account/subscriptions/redeem`, { code }))?.data;
      console.log(result);
      setResult(res);
    } catch (error) {
      console.log(error);
      setResult({ error: true });
      toast.addErrorToast({
        headline: 'Activation failed',
        text: 'Unfortunately the season pass could not be activated, please contact support.',
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (code && isApiReady) {
      redeemCode(code);
    }
  }, [code, isApiReady]);

  useEffect(() => {
    document.body.classList.toggle('no-scroll', openLoginModal);

    return () => document.body.classList.remove('no-scroll');
  }, [openLoginModal]);

  return (
    <>
      <Wrapper>
        {!accountState && !isLoading && <LoginCTA headline={Translations.sso.headlineSeasonPass} setOpenLoginModal={setOpenLoginModal} />}
        {accountState && isLoading && (
          <LoadingWrapper>
            <StyledLoadingAnimation />
            <Headline>Activating Season Pass</Headline>
          </LoadingWrapper>
        )}
        {result && (
          <StatusWrapper>
            <ResultHeadline color={result ? 'green' : 'red'}>{result ? 'Activation successful' : 'Activation failed'}</ResultHeadline>
            <ResultText>
              <>
                {result.subscribedUtil
                  ? `YOUR MOONCOURT PRO MEMBERSHIP IS VALID UNTIL ${new Date(result.subscribedUtil).toLocaleString('de-DE', { timeStyle: 'short', dateStyle: 'medium' })}`
                  : 'Unfortunately the season pass could not be activated, please contact support.'}
              </>
            </ResultText>
            <StyledLinkHandler to={'/'}>
              <Button>Go to Homepage</Button>
            </StyledLinkHandler>
          </StatusWrapper>
        )}
      </Wrapper>
      {openLoginModal && <LoginModal onClose={() => setOpenLoginModal(false)} />}
    </>
  );
}

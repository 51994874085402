import styled from 'styled-components';
import { useCallback, useEffect, useState } from 'react';
import ChevronIcon from '../../../icons/ChevronIcon';
import { Translations } from '../../../../utils/Translations';
import { PayloadWysiwyg } from '../../PayloadWysiwyg';
import Wysiwyg from '../../../common/Wysiwyg';
import { DetailPageSubHeadline } from './DetailPageStyledComponents';
import DetailPageSizeTable from './DetailPageSizeTable';
import { BREAKPOINT_LG, BREAKPOINT_MD } from '../../../../styles/Breakpoints';
import { IManufacturer, IMerchant } from 'src/services/shop';
import Modal from 'src/components/common/Modal';
import LinkHandler from 'src/components/common/LinkHandler';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-block-end: 3.75rem;

  ${BREAKPOINT_MD} {
    margin-block-end: 0;
  }
`;

export const AccordionHead = styled(DetailPageSubHeadline).attrs({ as: 'button', type: 'button' })`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  text-transform: uppercase;
  padding-block: 1.5rem 1rem;

  svg {
    font-size: 1.5rem;
    transition: transform 0.25s ease-in-out;

    path {
      stroke-width: 2px;
    }
  }

  @media (hover: hover) {
    &:hover {
      color: var(--color-primary-750);
    }
  }
`;

export const AccordionBody = styled.div`
  display: grid;
  transition: grid-template-rows 0.25s ease-in-out;
`;

export const Accordion = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  border-block-end: 1px solid var(--color-text-default);

  ${AccordionHead} {
    svg {
      transform: rotate(${({ open }) => (open ? 180 : 0)}deg);
    }
  }

  ${AccordionBody} {
    grid-template-rows: ${({ open }) => (open ? '1fr' : '0fr')};
  }
`;

export const AccordionInnerBody = styled.div`
  overflow: hidden;

  > :last-child {
    margin-block-end: 1.25rem;
  }
`;

const MerchantInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-block-start: 1.5rem;
`;

const MerchantHeadline = styled.p`
  font-size: var(--font-size-body-md);
  font-weight: 700;
`;

const MerchantButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: var(--font-size-body-md);
  text-decoration: underline;
  align-self: flex-start;

  &:hover {
    color: var(--color-primary-750);
  }
`;

export const MerchantLogo = styled.img`
  height: 1.5rem;
  width: auto;
  filter: invert(1);
`;

interface IDetailPageAccordionProps {
  purchaseInfo: string;
  productInfo: string;
  merchantInfo?: IMerchant;
  manufacturerInfo?: IManufacturer;
  openMerchantModal?: () => void;
}

export default function DetailPageAccordion(props: IDetailPageAccordionProps) {
  const { productInfo, purchaseInfo, merchantInfo, manufacturerInfo, openMerchantModal } = props;
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  return (
    <Wrapper>
      <Accordion open={currentIndex === 0}>
        <AccordionHead onClick={() => setCurrentIndex(currentIndex === 0 ? -1 : 0)}>
          {Translations.detailPage.accordion.purchaseInfoLabel} <ChevronIcon />
        </AccordionHead>

        <AccordionBody>
          <AccordionInnerBody>
            <Wysiwyg content={purchaseInfo.replace('|||', '')} />
            {merchantInfo && (
              <MerchantInfo>
                <MerchantHeadline>Sold and Shipped by:</MerchantHeadline>
                <MerchantButton type={'button'} onClick={openMerchantModal}>
                  <MerchantLogo src={merchantInfo.logo.url} alt={`${merchantInfo.name} Logo`} />
                  {merchantInfo.name}
                </MerchantButton>
              </MerchantInfo>
            )}
          </AccordionInnerBody>
        </AccordionBody>
      </Accordion>

      <Accordion open={currentIndex === 1}>
        <AccordionHead onClick={() => setCurrentIndex(currentIndex === 1 ? -1 : 1)}>
          {Translations.detailPage.accordion.productInfoLabel} <ChevronIcon />
        </AccordionHead>

        <AccordionBody>
          <AccordionInnerBody>
            <PayloadWysiwyg content={productInfo || []} />
          </AccordionInnerBody>
        </AccordionBody>
      </Accordion>

      <Accordion open={currentIndex === 2}>
        <AccordionHead onClick={() => setCurrentIndex(currentIndex === 2 ? -1 : 2)}>
          {Translations.detailPage.accordion.sizeChartLabel} <ChevronIcon />
        </AccordionHead>

        <AccordionBody>
          <AccordionInnerBody>
            <AccordionInnerBody>
              <DetailPageSizeTable />
            </AccordionInnerBody>
          </AccordionInnerBody>
        </AccordionBody>
      </Accordion>

      {manufacturerInfo && (
        <Accordion open={currentIndex === 3}>
          <AccordionHead onClick={() => setCurrentIndex(currentIndex === 3 ? -1 : 3)}>
            {Translations.detailPage.accordion.manufacturerLabel} <ChevronIcon />
          </AccordionHead>

          <AccordionBody>
            <AccordionInnerBody>
              <AccordionInnerBody>
                <p>{manufacturerInfo?.name}</p>
                <p>
                  {manufacturerInfo?.address?.street} {manufacturerInfo?.address?.houseNumber}
                </p>
                <p>
                  {manufacturerInfo?.address?.postalCode} {manufacturerInfo?.address?.city}
                </p>
                <p>{manufacturerInfo?.address?.country}</p>
                <a href={manufacturerInfo.contactRef.includes('@') ? `mailto:${manufacturerInfo.contactRef}` : manufacturerInfo.contactRef}>
                  {manufacturerInfo.contactRef}
                </a>
                {manufacturerInfo?.noEuLocation && (
                  <div>
                    <br />
                    <p>{manufacturerInfo?.extraInfo?.name}</p>
                    <p>
                      {manufacturerInfo?.extraInfo?.address?.street} {manufacturerInfo?.extraInfo?.address?.houseNumber}
                    </p>
                    <p>
                      {manufacturerInfo?.extraInfo?.address?.postalCode} {manufacturerInfo?.extraInfo?.address?.city}
                    </p>
                    <p>{manufacturerInfo?.extraInfo?.address?.country}</p>
                    <a
                      href={
                        manufacturerInfo?.extraInfo?.contactRef.includes('@')
                          ? `mailto:${manufacturerInfo?.extraInfo?.contactRef}`
                          : manufacturerInfo?.extraInfo?.contactRef
                      }>
                      {manufacturerInfo?.extraInfo?.contactRef}
                    </a>
                  </div>
                )}
              </AccordionInnerBody>
            </AccordionInnerBody>
          </AccordionBody>
        </Accordion>
      )}
    </Wrapper>
  );
}

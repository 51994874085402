import styled, { css } from 'styled-components';
import LinkHandler from '../../common/LinkHandler';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  container-type: inline-size;
  width: 100%;
  aspect-ratio: 3 / 5;
`;

export const CardWrapper = styled.div<{ rarity?: string; hasHover?: boolean }>`
  position: relative;
  display: flex;
  height: 100%;
  border-radius: 15cqw;
  padding: 4cqw;
  background: linear-gradient(128deg, #313131 10.5%, #1c1c1c 52.84%, #313131 82.44%);
  border: 2px solid var(--color-rarity-${({ rarity = 'common' }) => rarity});
  transition: background-color 0.5s ease-in-out;
  overflow: hidden;
  box-shadow:
    3px 3px 3px 0 rgba(255, 255, 255, 0.4) inset,
    -3px -3px 3px 0 rgba(0, 0, 0, 0.4) inset;

  --rarity-gradient-hover: var(--gradient-rarity-${({ rarity = 'common' }) => rarity});
  --rarity-box-shadow: var(--box-shadow-rarity-${({ rarity = 'common' }) => rarity});

  ${({ hasHover = true }) =>
    hasHover &&
    css`
      ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--rarity-gradient-hover);
        opacity: 0;
        transition: opacity 0.25s ease-in-out;
        box-shadow: var(--rarity-box-shadow);
        border-radius: 15cqw;
        z-index: 0;
      }
    `};

  @media (hover: hover) {
    :hover {
      text-decoration: none;

      ${({ hasHover = true }) =>
        hasHover &&
        css`
          ::before {
            opacity: 1;
          }
        `}
    }
  }
`;

export const ShopCardWrapper = styled(CardWrapper)`
  border-color: #666;
`;

export const InnerCardWrapper = styled.div`
  position: relative;
  display: grid;
  flex-direction: column;
  border-radius: 12.33cqw;
  box-shadow: 0 0 7px 0 #000;
  overflow: hidden;
  width: 100%;
  z-index: 1;
`;

export const CardImageWrapper = styled.div<{ hasGradient?: boolean; itemAvailable?: boolean }>`
  position: relative;
  display: flex;
  grid-area: 1 / 1;

  ${({ hasGradient = true }) =>
    hasGradient &&
    css`
      ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 59.66%, rgba(0, 0, 0, 0.85) 89.88%);
      }
    `}
  img, video {
    height: 100%;
    object-fit: cover;
    opacity: ${({ itemAvailable = true }) => (itemAvailable ? `100%` : `50%`)};
  }
`;

export const CardBody = styled.div`
  grid-area: 1 / 1;
  align-self: flex-end;
  display: grid;
  //gap: 1rem;
  gap: 5.33cqw;
  //padding-inline: 1rem;
  padding-inline: 5.33cqw;
  //padding-block: 1.25rem;
  padding-block: 6.66cqw;
  z-index: 1;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const CardId = styled.h6`
  //font-size: 0.75rem;
  font-size: 4cqw;
  line-height: 1;
  font-weight: 700;
`;

export const CardTitle = styled.h3<{ hasShadow?: boolean }>`
  //font-size: 1.5rem;
  font-size: 8cqw;
  line-height: 1.1;
  text-transform: uppercase;
  font-weight: 700;

  ${({ hasShadow }) =>
    hasShadow &&
    css`
      text-shadow: 0 0 10px var(--color-text-highlight);
    `};
`;

export const CardAttributes = styled.div`
  display: grid;
  //gap: 0.25rem;
  gap: 1.33cqw;
`;

export const CardAttribute = styled.div<{ lowerCase?: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 10.66cqw;
  font-size: 4cqw;
  line-height: 1;
  font-weight: 700;
  overflow: hidden;

  p:first-child {
    text-transform: uppercase;
  }

  p:last-child {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: ${({ lowerCase = false }) => (lowerCase ? 'default' : 'uppercase')};
  }
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    font-size: 8cqw;
  }
`;

export const CardPrice = styled.h5`
  display: flex;
  align-items: center;
  font-size: 6cqw;
  line-height: 1;
  gap: 1.33cqw;
`;

export const CardOpenSeaLink = styled(LinkHandler)`
  display: flex;

  @media (hover: hover) {
    :hover {
      color: var(--color-primary-750);
    }
  }
`;

export const CardLabel = styled.span`
  width: max-content;
  font-size: 4cqw;
  line-height: 1;
  font-weight: 700;
  color: var(--color-text-default);
  background: var(--color-text-highlight);
  padding: 1.33cqw;
  border-radius: 0.25rem;
  text-transform: uppercase;
`;

export const MerchantLogo = styled.img`
  height: 1.75rem;
  width: auto;
`;

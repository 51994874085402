import styled from 'styled-components';
import Mooncourt from '../../icons/Mooncourt';
import { FontFamilies } from '../../../styles/FontFamilies';
import { BREAKPOINT_XXL } from '../../../styles/Breakpoints';
import { Button } from '../../common/Button';
import { Translations } from 'src/utils/Translations';
import { useIsApiReady } from 'src/services/ApiProvider';
import { useCallback } from 'react';
import { useResetUserState } from 'src/states/AppData';

const Wrapper = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  border-radius: 1.25rem;
  border: 1px solid #fff;
  background: var(--color-black-900);
  padding: 2rem 3.75rem;
  backdrop-filter: blur(6px);
  max-width: 35rem;

  ${BREAKPOINT_XXL} {
    grid-column: 13 / 21;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  svg {
    font-size: 1.5rem;
    color: var(--color-primary-750);
  }
`;

const Headline = styled.h4`
  font-family: ${FontFamilies.headline};
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
`;

const LoginButton = styled(Button)`
  align-self: center;
  width: 10rem;
  justify-content: center;
`;

interface ILoginCTA {
  headline?: string;
  setOpenLoginModal: (value: boolean) => void;
}

export default function LoginCTA(props: ILoginCTA) {
  const { headline = Translations.sso.headline, setOpenLoginModal } = props;
  const isApiReady = useIsApiReady();
  const resetUserState = useResetUserState();

  const handleLoginClick = useCallback(() => {
    if (isApiReady) {
      resetUserState();
    } else {
      setOpenLoginModal(true);
    }
  }, [isApiReady]);

  return (
    <Wrapper>
      <Header>
        <Mooncourt />
        <Headline>{headline}</Headline>
      </Header>

      <LoginButton disabled={isApiReady} onClick={handleLoginClick}>
        {'Login'}
      </LoginButton>
    </Wrapper>
  );
}

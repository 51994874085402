import styled from 'styled-components';
import { Children, PropsWithChildren } from 'react';
import { NFTPlaceholder, NFTPlaceholderProps } from '../NFTPlaceholder';
import { BREAKPOINT_MD } from 'src/styles/Breakpoints';

const Wrapper = styled.div`
  --min-cols: 2;
  --max-cols: 4;
  --cols-min-width: 150px;
  --grid-row-gap: 1.5rem;
  --grid-column-gap: 0.75rem;

  display: grid;
  gap: var(--grid-row-gap) var(--grid-column-gap);
  grid-template-columns: repeat(
    auto-fill,
    minmax(
      min(
        (100% / var(--min-cols) - var(--grid-column-gap) * (var(--min-cols) - 1) / var(--min-cols)),
        max(var(--cols-min-width), (100% / var(--max-cols) - var(--grid-column-gap) * (var(--max-cols) - 1) / var(--max-cols)))
      ),
      1fr
    )
  );

  ${BREAKPOINT_MD} {
    --grid-column-gap: 1.5rem;
  }
`;

interface CardGridProps extends PropsWithChildren {
  loading: boolean;
  placeholder: NFTPlaceholderProps;
}

export default function CardGrid(props: CardGridProps) {
  const { children, loading, placeholder } = props;

  return <Wrapper>{loading || Children.count(children) === 0 ? <NFTPlaceholder {...placeholder} loading={loading} /> : children}</Wrapper>;
}

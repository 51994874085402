import styled from 'styled-components';
import { FooterLinks, NavigationIconLinks } from './BurgerMenu';
import IconLink from '../../common/IconLink';
import LinkHandler from '../../common/LinkHandler';
import { BREAKPOINT_MD } from 'src/styles/Breakpoints';

const Wrapper = styled.div`
  margin-block-start: 2.5rem;
`;

const InnerWrapper = styled.div`
  display: flex;
  visibility: initial;
  flex-wrap: wrap;
  gap: 0.5rem 3.75rem;
  margin-inline: calc(-1 * var(--content-spacing));
  padding-inline: var(--content-spacing);
  padding-block: 0.75rem;
  background: var(--theme-black-black-50, rgba(0, 0, 0, 0.8));
  backdrop-filter: blur(5px);
  justify-content: center;

  ${BREAKPOINT_MD} {
    justify-content: space-between;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  gap: 0.25rem 1rem;
  --size: 1.5rem;
  flex-wrap: wrap;
  justify-content: center;

  a {
    @media (hover: hover) {
      :hover {
        color: var(--color-primary-750);
      }
    }
  }
`;

const LegalLink = styled(LinkHandler)`
  font-size: 0.75rem;
`;

export default function Footer() {
  return (
    <Wrapper>
      <InnerWrapper>
        <LinkWrapper>
          {NavigationIconLinks.map((item, index) => (
            <IconLink {...item} key={index} />
          ))}
        </LinkWrapper>

        <LinkWrapper>
          {FooterLinks.map(({ title, target, href }, index) => (
            <LegalLink key={index} to={href} title={title} target={target}>
              {title}
            </LegalLink>
          ))}
        </LinkWrapper>
      </InnerWrapper>
    </Wrapper>
  );
}

// General
import { SVGProps } from 'react';

export default function TicketIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      className='lucide lucide-ticket w-3 h-3 flex-shrink-0'
      data-lov-id='src/components/ui/raffle-card.tsx:144:14'
      data-lov-name='Ticket'
      data-component-path='src/components/ui/raffle-card.tsx'
      data-component-line='144'
      data-component-file='raffle-card.tsx'
      data-component-name='Ticket'
      data-component-content='%7B%22className%22%3A%22w-3%20h-3%20flex-shrink-0%22%7D'>
      <path d='M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z'></path>
      <path d='M13 5v2'></path>
      <path d='M13 17v2'></path>
      <path d='M13 11v2'></path>
    </svg>
  );
}

import styled from 'styled-components';
import { BREAKPOINT_MD } from 'src/styles/Breakpoints';
import PointsIcon from 'src/components/icons/PointsIcon';
import {
  Countdown,
  CountdownBody,
  CountdownHead,
  CountdownWrapper,
  TrainingsBoxHeadline,
  TrainingsBoxWrapper,
} from 'src/components/content/training/subcomponents/TrainingBox';
import { useMemo } from 'react';
import { useCountdown } from 'src/hooks/useCountdown';
import StatTile from 'src/components/content/dashboard/StatTile';
import Tooltip, { AbsoluteWrapper } from 'src/components/common/Tooltip';
import { Translations } from 'src/utils/Translations';
import { formatPrice } from 'src/utils/price';

const SeasonPassHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTooltip = styled(Tooltip)`
  z-index: 10;
  font-size: 1.5rem;
  margin-block-end: 1rem;

  ${AbsoluteWrapper} {
    right: 0;
    width: calc(100vw - 2 * var(--content-spacing) - 2 * 0.75rem);

    ${BREAKPOINT_MD} {
      max-width: 32rem;
    }
  }
`;

const TileWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.75rem;
`;

const StyledStatTile = styled(StatTile)`
  ${BREAKPOINT_MD} {
    width: auto;
  }
`;

interface ISeasonPassBoxProps {
  points: number;
  boost: number;
}

export default function SeasonPassBox(props: ISeasonPassBoxProps) {
  const { points, boost } = props;

  const countdownDate = useMemo(() => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
  }, []);

  const [days, hours, minutes, seconds] = useCountdown(countdownDate.toDateString());

  return (
    <TrainingsBoxWrapper>
      <SeasonPassHeader>
        <TrainingsBoxHeadline>SEASON PASS POINTS</TrainingsBoxHeadline>
        <StyledTooltip translation={Translations.training.seasonPass.tooltip} />
      </SeasonPassHeader>

      <CountdownWrapper>
        <CountdownHead>
          <p>next mp in</p>
          <Countdown>{seconds === '-1' ? '00:00:00:00' : `${days}:${hours}:${minutes}:${seconds}`}</Countdown>
        </CountdownHead>

        <CountdownBody>
          <PointsIcon /> {formatPrice(Math.floor(points * boost))}
        </CountdownBody>
      </CountdownWrapper>

      <TileWrapper>
        <StyledStatTile value={points} name={'MP'} />
        <StyledStatTile value={boost} name={'Boost'} />
      </TileWrapper>
    </TrainingsBoxWrapper>
  );
}

import styled, { css } from 'styled-components';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import AnimatedTabSwitch from '../../common/AnimatedTabSwitch';
import { useCallback, useMemo } from 'react';
import { BREAKPOINT_LG } from 'src/styles/Breakpoints';
import Mooncourt from 'src/components/icons/Mooncourt';

const Wrapper = styled.div<{ currentIndex: number }>`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;

  ${BREAKPOINT_LG} {
    width: 100%;

    ${({ currentIndex }) =>
      currentIndex === 0 &&
      css`
        width: auto;
      `};
  }
`;

const StyledAnimatedTabSwitch = styled(AnimatedTabSwitch)`
  padding-inline: var(--content-spacing);
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  ${BREAKPOINT_LG} {
    flex-direction: row;
  }
`;

const OverflowWrapper = styled.div`
  margin-inline: calc(-1 * var(--content-spacing));
  display: flex;
  overflow: hidden;
`;

const ProfileBanner = styled.div`
  display: flex;
  align-items: center;

  ${BREAKPOINT_LG} {
    border: 1px solid var(--color-grey-700);
    border-radius: 3rem;
    padding: 0.25rem;
  }
`;

const ProfileLogo = styled.div`
  display: none;

  ${BREAKPOINT_LG} {
    display: grid;
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 100%;
    place-content: center;
    background: var(--color-grey-900);
    color: var(--color-primary-750);
  }
`;

const ProfileHeadline = styled.h3`
  font-size: var(--font-size-headline-md);
  text-transform: uppercase;

  ${BREAKPOINT_LG} {
    margin-block-start: 0.25rem;
    margin-inline: 1.5rem 2rem;
  }
`;

const ProfileTabItems = [
  { label: 'Account', path: 'account' },
  { label: 'Address', path: 'address' },
  { label: 'Wallets', path: 'wallet' },
  { label: 'History', path: 'history' },
];

export default function Profile() {
  const location = useLocation();
  const navigate = useNavigate();

  const currentIndex = useMemo(() => {
    switch (location.pathname) {
      case '/profile/history':
        return 3;
      case '/profile/wallet':
        return 2;
      case '/profile/address':
        return 1;
      default:
        return 0;
    }
  }, [location]);

  const changeIndex = useCallback(
    (index: number) => {
      navigate(`/profile/${ProfileTabItems[index].path}`);
    },
    [navigate],
  );

  return (
    <Wrapper currentIndex={currentIndex}>
      <Header>
        <ProfileBanner>
          <ProfileLogo>
            <Mooncourt />
          </ProfileLogo>
          <ProfileHeadline>your profile</ProfileHeadline>
        </ProfileBanner>

        <OverflowWrapper>
          <StyledAnimatedTabSwitch currentIndex={currentIndex} items={ProfileTabItems.map((item) => item.label)} onClick={changeIndex} />
        </OverflowWrapper>
      </Header>

      <Outlet />
    </Wrapper>
  );
}

import styled, { css, keyframes } from 'styled-components';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_SM, BREAKPOINT_XL } from '../../styles/Breakpoints';
import { Button } from '../common/Button';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  width: 100%;
`;

export const AppHeadline = styled.h2`
  line-height: 1.3;
  text-transform: uppercase;
  font-size: 1.5rem;

  ${BREAKPOINT_SM} {
    font-size: 1.875rem;
  }

  ${BREAKPOINT_LG} {
    font-size: 2.5rem;
  }
`;

export const InternalStyledButton = styled(Button)<{ theme?: 'primary' | 'secondary' | 'tertiary' | 'inverted' | undefined }>`
  padding-inline: 1.25rem;
  height: 2.5rem;
  font-size: 0.8125rem;

  ${BREAKPOINT_MD} {
    font-size: 1rem;
  }
`;

export const RefreshButton = styled(InternalStyledButton)`
  width: 2.5rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const InternalItemWrapper = styled.div<{ visible?: boolean }>`
  display: grid;
  gap: 1.25rem;
  grid-column: 1 / -1;

  ${BREAKPOINT_SM} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${BREAKPOINT_LG} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${BREAKPOINT_XL} {
    grid-template-columns: repeat(4, 1fr);
  }

  ${({ visible = true }) =>
    !visible &&
    css`
      content-visibility: hidden;

      @supports not (content-visibility: visible) {
        visibility: hidden;
      }
    `};
`;

const PlaceholderShimmer = keyframes`
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
`;

export const ShimmerLoadingAnimation = css`
  position: relative;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: linear-gradient(to right, transparent 30%, rgba(255, 255, 255, 0.75) 50%, transparent 70%);
    background-size: 200% 100%;
    animation: 1.5s ${PlaceholderShimmer} infinite forwards linear;
  }
`;

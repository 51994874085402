import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useAccount } from 'wagmi';
import { useRecoilValue } from 'recoil';
import useToast from '../../../hooks/useToast';
import { Notifications } from './Layout';
import Mooncourt from '../../icons/Mooncourt';
import { BREAKPOINT_MD, BREAKPOINT_XL, BREAKPOINT_XXL, DIMEN_BREAKPOINT_MD } from '../../../styles/Breakpoints';
import BurgerMenu, { NavItems } from './BurgerMenu';
import LinkHandler from '../../common/LinkHandler';
import { useIsApiReady } from '../../../services/ApiProvider';
import { useRewardApi } from '../../../services/reward';
import ProfileMenu from './subcomponents/ProfileMenu';
import { useUserApi } from '../../../services/user';
import { AccountState, WalletState } from '../../../states/AppData';
import LoginModal from '../../content/user/LoginModal';
import UserMetaData from './subcomponents/UserMetaData';
import { useLocation } from 'react-router-dom';
import { FontFamilies } from '../../../styles/FontFamilies';
import OnBoardingModal from 'src/components/content/user/OnBoardingModal';
import { Button } from 'src/components/common/Button';

const Wrapper = styled.header<{ hasBackground: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-block: 0.5rem 1.5rem;

  ${BREAKPOINT_MD} {
    padding-block: 1.25rem;
  }

  ${BREAKPOINT_XL} {
    position: sticky;
    top: 0;
    z-index: 10;
    margin-block-end: 2rem;

    ::before {
      content: '';
      background: rgba(0, 0, 0, 0.8);
      position: absolute;
      width: calc(100% + (var(--content-spacing) * 2)); // TODO: CHECK IF THIS COURSES OTHER BUGS
      height: 100%;
      backdrop-filter: blur(5px);
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.5);
      display: flex;
      top: 0;
      margin-inline: calc(-1 * var(--content-spacing));
      opacity: ${({ hasBackground }) => (hasBackground ? 1 : 0)};
      transition: opacity 0.3s ease-in-out;
    }
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  align-items: center;
`;

const LogoLink = styled(LinkHandler)`
  display: grid;
  font-size: 2.125rem;
  color: var(--color-primary-750);
  line-height: 1;

  ${BREAKPOINT_MD} {
    font-size: 2.75rem;
    width: 3.5rem;
    place-content: center;
  }

  @media (hover: hover) {
    &:hover {
      color: var(--color-text-default);
    }
  }
`;

const NavItemWrapper = styled.div<{ visible: boolean }>`
  z-index: 1;
  display: flex;
  gap: 2.5rem;
  overflow: hidden;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? 'initial' : 'none')};
  pointer-events: ${({ visible }) => (visible ? 'initial' : 'none')};

  ${BREAKPOINT_XXL} {
    gap: 3.75rem;
  }
`;

const StyledLinkHandler = styled(LinkHandler)`
  display: flex;
  gap: 0.375rem;

  :hover {
    text-decoration: none;
    color: var(--color-primary-750);
  }
`;

const StyledMembershipLinkHandler = styled(StyledLinkHandler)`
  ${BREAKPOINT_MD} {
    display: none;
  }
`;

const NavItem = styled.div<{ active: boolean; disabled: boolean }>`
  text-transform: uppercase;
  font-size: 1.25rem;
  font-weight: 700;
  font-family: ${FontFamilies.headline};
  color: var(${({ active }) => (active ? '--color-primary-750' : '-color-text-default')});

  svg {
    font-size: 1.5rem;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      a {
        pointer-events: none;
        color: var(--color-grey-650);
      }
    `}
`;

const HeaderEnd = styled.div`
  display: flex;
  gap: inherit;
  align-items: flex-end;
  z-index: 10;
`;

const MembershipButton = styled(Button)`
  font-size: var(--font-size-body-md);
  font-weight: 600;
  padding-inline: 0.875rem;
  height: 2.375rem;

  ${BREAKPOINT_MD} {
    height: 2.75rem;
  }
`;

interface IHeader {
  className?: string;
}

export default function Header(props: IHeader) {
  const { className } = props;
  const { address } = useAccount();
  const [loading, setLoading] = useState<boolean>(true);
  const { addToast, addErrorToast } = useToast();
  const notifications = useContext(Notifications);
  const [hasBackground, setHasBackground] = useState(false);
  const [displayedConnectWalletToast, setDisplayedConnectWalletToast] = useState(false);

  const [openNav, setOpenNav] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openOnboardingModal, setOpenOnboardingModal] = useState<false | 'username' | 'newsletter'>(false);
  const [hasEnoughSpaceForNavigation, setHasEnoughSpaceForNavigation] = useState(false);

  const accountState = useRecoilValue(AccountState);
  const walletState = useRecoilValue(WalletState);
  const headerRef = useRef<HTMLDivElement>(null);

  const location = useLocation();

  const { refreshAccountState, refreshWalletState, refreshAddressState } = useUserApi();
  const { claimDailyRewards } = useRewardApi();
  const isApiReady = useIsApiReady();

  const showMembershipButton = useMemo(() => {
    if (!accountState) return true;
    return accountState.account.level.length === 1 && accountState.account.level[0] === 'free';
  }, [accountState]);

  const populatedNavItems = useMemo(
    () =>
      NavItems.map((item) => ({
        ...item,
        active: item.href === '/' ? location.pathname === item.href : location.pathname.includes(item.href),
      })),
    [location],
  );

  const fetchData = useCallback(async () => {
    if (isApiReady) {
      setLoading(true);

      claimDailyRewards()
        .then(async (rewardsResponse) => {
          await Promise.all([await refreshAccountState(), await refreshWalletState(), await refreshAddressState()]);

          if (rewardsResponse?.pointsAdded && notifications) {
            addToast({
              headline: notifications.dailyRewardPointsAdded.headline,
              text: notifications.dailyRewardPointsAdded.text.replace('{POINTS}', rewardsResponse.pointsAdded.toString()),
              type: 'success',
            });
          }
        })
        .catch((error) => {
          console.log('error claiming daily rewards:', error);
          addErrorToast(notifications!.internalServerError);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApiReady]);

  useEffect(() => {
    if (accountState) {
      if (!accountState?.account.username) {
        setOpenOnboardingModal('username');
      } else if (accountState?.newsletter?.popUpShown === false) {
        setOpenOnboardingModal('newsletter');
      }
    }
  }, [accountState]);

  const scrollHandler = useCallback(() => {
    setHasBackground(!!window.scrollY);
  }, []);

  useEffect(() => {
    fetchData();

    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, fetchData, isApiReady]);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (headerRef.current) {
        if (window.innerWidth < DIMEN_BREAKPOINT_MD) {
          setHasEnoughSpaceForNavigation(false);
        } else {
          const containerWidth = headerRef.current.clientWidth;
          const itemWidth =
            (headerRef.current.children.item(0)?.scrollWidth || 0) +
            (headerRef.current.children.item(1)?.scrollWidth || 0) +
            (headerRef.current.children.item(2)?.scrollWidth || 0);

          setHasEnoughSpaceForNavigation(containerWidth - itemWidth - 64 > 0);
        }
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [headerRef]);

  useEffect(() => {
    if (hasEnoughSpaceForNavigation) {
      setOpenNav(false);
    }
  }, [hasEnoughSpaceForNavigation]);

  useEffect(() => {
    if (isApiReady && walletState?.length === 0 && !displayedConnectWalletToast) {
      notifications && addToast({ ...notifications.connectWalletReminder, type: 'info', autoClose: 5000 });
      setDisplayedConnectWalletToast(true);
    }
  }, [walletState, isApiReady]);

  useEffect(() => {
    document.body.classList.toggle('no-scroll', openLoginModal);

    return () => document.body.classList.remove('no-scroll');
  }, [openLoginModal]);

  return (
    <Wrapper className={className} hasBackground={hasBackground}>
      <Container ref={headerRef}>
        <LogoLink to='/'>
          <Mooncourt />
        </LogoLink>

        <NavItemWrapper visible={hasEnoughSpaceForNavigation}>
          {populatedNavItems.map(({ icon, title, href, target, active, requiresLogin }, index) => (
            <NavItem key={index} active={active} disabled={requiresLogin && !isApiReady}>
              <StyledLinkHandler to={href} target={target}>
                {icon} <p>{title}</p>
              </StyledLinkHandler>
            </NavItem>
          ))}
        </NavItemWrapper>

        <HeaderEnd>
          {showMembershipButton && !hasEnoughSpaceForNavigation && (
            <StyledMembershipLinkHandler to={'/membership'}>
              <MembershipButton>Membership</MembershipButton>
            </StyledMembershipLinkHandler>
          )}

          <UserMetaData loading={loading} />

          <ProfileMenu />

          {!hasEnoughSpaceForNavigation && <BurgerMenu openNav={openNav} setOpenNav={setOpenNav} />}
        </HeaderEnd>
      </Container>

      {openLoginModal && <LoginModal onClose={() => setOpenLoginModal(false)} />}
      {openOnboardingModal && <OnBoardingModal onClose={() => setOpenOnboardingModal(false)} initialStep={openOnboardingModal} />}
    </Wrapper>
  );
}

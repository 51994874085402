export function formatPrice(price: number | string | undefined) {
  if (price === undefined) {
    return '0';
  } else if (price.toString().length < 5) return price.toString();
  else {
    const priceArray = Math.floor(parseInt(price.toString())).toString().split('');

    const formattedArray = priceArray
      .reverse()
      .reduce(
        (prev, curr, index) => {
          if ((index + 1) % 3 === 0 && index !== priceArray.length - 1) {
            return [...prev, curr, '.'];
          } else {
            return [...prev, curr];
          }
        },
        [''],
      )
      .reverse();

    return formattedArray.join('');
  }
}

import { useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';

// Types
import { ShopHistoryItemProps } from '../../../../services/shop';

// Icons
import CopyIcon from '../../../icons/CopyIcon';
import PointsIcon from '../../../icons/PointsIcon';

// Components
import LinkHandler from '../../../common/LinkHandler';
import CheckmarkIcon from '../../../icons/CheckmarkIcon';
import { formatPrice } from 'src/utils/price';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  background: linear-gradient(152.56deg, #3c3c3c 1.24%, #0a0a0a 101.84%);
  border-radius: 0.625rem;
`;

const ImageWrapper = styled.div`
  position: relative;
  aspect-ratio: 1 / 1;
  background-color: var(--color-text-default);
  box-shadow: 0 7px 12px 0 rgba(0, 0, 0, 0.55);
  border-radius: 0.625rem;
  //padding: 1.25rem;
  margin-block-end: 0.875rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const Image = styled.img`
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
`;

const ItemId = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.375rem;
  color: var(--color-text-highlight);
  font-size: 0.875rem;
  line-height: 1;
  font-family: var(--font-family-headline);
`;

const ClipboardAlert = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.75rem;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.625rem;
  text-transform: uppercase;
  font-weight: 700;
  white-space: nowrap;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  opacity: 0;
  transition: opacity 0s ease-in-out;
  pointer-events: none;
`;

const StatusBanner = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  color: var(--color-text-default);
  text-transform: uppercase;
  font-family: var(--font-family-headline);
  font-size: 0.75rem;
  line-height: 1;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  font-family: var(--font-family-headline);
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 700;
  text-transform: uppercase;
  margin-block-end: 0.75rem;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const MetaDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
`;

const MetaData = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  line-height: 1.5;
  gap: 0.75rem;
  text-align: end;
`;

const Label = styled.label`
  font-weight: 700;
  text-transform: uppercase;
`;

const StyledLink = styled(LinkHandler)`
  text-decoration: underline;

  :hover {
    color: var(--color-signal-red);
  }
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

interface HistoryItemCardProps extends ShopHistoryItemProps {
  status?: string;
  loadLazy?: boolean;
  amount?: number;
}

export default function HistoryItemCard(props: HistoryItemCardProps) {
  const { id, item, itemVariant, redeemedAt, purchasedAt, status, shopLink, paymentLink, shippingLink, loadLazy, amount } = props;

  const alertRef = useRef<HTMLDivElement | null>(null);

  const selectedVariant = useMemo(() => {
    return item.variants.find((variant) => variant.id === itemVariant);
  }, [item, itemVariant]);

  const copyId = useCallback(async () => {
    await navigator.clipboard?.writeText(id);
    if (alertRef?.current) {
      alertRef.current.style.opacity = '1';
      alertRef.current.style.transitionDuration = '0s';
      setTimeout(() => {
        alertRef.current!.style.transitionDuration = '1s';
        alertRef.current!.style.opacity = '0';
      }, 1000);
    }
  }, [id]);

  return (
    <Wrapper>
      <ImageWrapper>
        <Image
          src={selectedVariant?.images?.[0].image.url}
          alt={selectedVariant?.images?.[0].image.alt}
          loading={loadLazy ? 'lazy' : undefined}
        />

        <ItemId onClick={copyId}>
          {`ID ${id.slice(0, 3)}...${id.slice(id.length - 3, id.length)}`}
          <CopyIcon />
        </ItemId>

        <ClipboardAlert ref={alertRef}>
          Copied to clipboard! <CheckmarkIcon />
        </ClipboardAlert>

        {status && (
          <StatusBanner>
            <p>status</p>
            <p>{status}</p>
          </StatusBanner>
        )}
      </ImageWrapper>

      <Header>
        <p>
          {amount ? amount + 'x' : ''} {item.name}
        </p>
        <Price>
          <PointsIcon /> {formatPrice(selectedVariant?.price || 0)} MP
        </Price>
      </Header>

      <Body>
        <MetaDataWrapper>
          <MetaData>
            <Label>variant</Label>
            <p>{selectedVariant?.attributes.map(({ type, value }) => `${type}: ${value}`).join(' | ')}</p>
          </MetaData>
          <MetaData>
            <Label>purchased at</Label>
            <p>{purchasedAt ? new Date(purchasedAt).toLocaleDateString() : '-'}</p>
          </MetaData>
          <MetaData>
            <Label>processed at</Label>
            <p>{redeemedAt ? new Date(redeemedAt).toLocaleDateString() : '-'}</p>
          </MetaData>
        </MetaDataWrapper>

        <MetaDataWrapper>
          <MetaData>
            <Label>shop link</Label>
            {shopLink ? <StyledLink to={shopLink}>Link</StyledLink> : <p>-</p>}
          </MetaData>
          <MetaData>
            <Label>payment link</Label>
            {paymentLink ? <StyledLink to={paymentLink}>Link</StyledLink> : <p>-</p>}
          </MetaData>
          <MetaData>
            <Label>tracking link</Label>
            {shippingLink ? <StyledLink to={shippingLink}>Link</StyledLink> : <p>-</p>}
          </MetaData>
        </MetaDataWrapper>
      </Body>
    </Wrapper>
  );
}

import { RaffleProps, ShopItemProps } from '../../../../services/shop';
import RaffleTicketCard from '../../cards/RaffleTicketCard';
import ShopItemCard from '../../cards/ShopItemCard';
import styled from 'styled-components';
import { CardContainer } from '../../cards/CardStyles';
import { DetailPageSubHeadline } from './DetailPageStyledComponents';
import { Translations } from '../../../../utils/Translations';
import LinkHandler from '../../../common/LinkHandler';
import { HideScrollbarStyles } from '../../../../styles/Custom';
import { BREAKPOINT_XL, DIMEN_BOXED_CONTENT_WIDTH } from '../../../../styles/Breakpoints';
import { FontFamilies } from '../../../../styles/FontFamilies';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import RaffleCard from '../../cards/RaffleCard';

const Wrapper = styled.div`
  display: grid;
  gap: 1.25rem;
  grid-column: 1 / -1;
`;

const Header = styled.div`
  display: flex;
  gap: 1.25rem;
  align-items: flex-end;
  justify-content: space-between;
`;

const StyledLinkHandler = styled(LinkHandler)`
  font-family: ${FontFamilies.headline};
  font-weight: 700;
  text-decoration: underline;
  white-space: nowrap;

  &:hover {
    color: var(--color-primary-750);
  }
`;

const OverflowWrapper = styled.div`
  overflow-x: scroll;
  margin-inline: -1rem;
  padding-inline: 1rem;
  //transform: rotate(-90deg);
`;

const ItemsWrapper = styled.div`
  display: flex;
  gap: 1.25rem;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  ${HideScrollbarStyles};
  //transform: rotate(90deg);
`;

const Item = styled.div`
  scroll-snap-align: start;

  ${CardContainer} {
    width: 300px;

    ${BREAKPOINT_XL} {
      width: calc((${DIMEN_BOXED_CONTENT_WIDTH}px - 5rem) / 5);
    }
  }
`;

const SwiperWrapper = styled.div`
  display: grid;
  margin-inline: calc(-1 * var(--content-spacing));

  ${BREAKPOINT_XL} {
    margin-inline: 0;
  }
`;

const StyledSwiper = styled(Swiper)`
  display: flex;
  width: 100%;
  padding-inline: var(--content-spacing);
  margin-block: -1rem;
  padding-block: 1rem;

  ${BREAKPOINT_XL} {
    padding-inline: 0;
  }
`;

interface IDetailPageRecommendationsProps {
  recommendations: Array<ShopItemProps | RaffleProps>;
}

export default function DetailPageRecommendations(props: IDetailPageRecommendationsProps) {
  const { recommendations } = props;

  return (
    <Wrapper>
      <Header>
        <DetailPageSubHeadline>{Translations.detailPage.recommendations.headline}</DetailPageSubHeadline>

        <StyledLinkHandler to={'/shop'}>{Translations.detailPage.recommendations.link}</StyledLinkHandler>
      </Header>

      <SwiperWrapper>
        <StyledSwiper
          slidesPerView={1.25}
          spaceBetween={16}
          breakpoints={{
            576: {
              slidesPerView: 2.25,
              spaceBetween: 16,
            },
            768: {
              slidesPerView: 3.25,
              spaceBetween: 20,
            },
            992: {
              slidesPerView: 4.25,
              spaceBetween: 20,
            },
            1440: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
          }}>
          {recommendations
            .filter((item) => 'item' in item)
            .map((item) => (
              <SwiperSlide key={item.id}>
                <RaffleCard {...(item as any)} />
              </SwiperSlide>
            ))}
        </StyledSwiper>
      </SwiperWrapper>
    </Wrapper>
  );
}

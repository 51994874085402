import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { WagmiProvider, createConfig, http, injected } from 'wagmi';
import { mainnet, sepolia } from 'wagmi/chains';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { metaMask, walletConnect, coinbaseWallet } from 'wagmi/connectors';
/**
 * WAGMI client used as host for multiple
 * hooks to check the current wallet status
 */

const queryClient = new QueryClient();

// @ts-ignore
// @ts-ignore
export const config = createConfig({
  chains: [mainnet, sepolia],
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http(),
  },
  connectors: [coinbaseWallet(), walletConnect({ projectId: '4a17b3ff695b22a7cf8b7b4b8326a8a8' }), metaMask()],
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>,
);

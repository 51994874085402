import styled from 'styled-components';
import PointsIcon from '../../../icons/PointsIcon';
import { DetailPageSubHeadline } from './DetailPageStyledComponents';
import { formatPrice } from 'src/utils/price';

const PriceWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-block-end: 0.75rem;

  svg {
    font-size: 1.5rem;
  }
`;

interface IDetailPagePriceProps {
  price: number | string;
}

export default function DetailPagePrice(props: IDetailPagePriceProps) {
  const { price } = props;

  return (
    <PriceWrapper>
      <PointsIcon />
      <DetailPageSubHeadline>
        {formatPrice(parseInt(price.toString()))} MOONPOINT{price !== 1 && 'S'}
      </DetailPageSubHeadline>
    </PriceWrapper>
  );
}

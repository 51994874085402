import { createGlobalStyle } from 'styled-components';
import { FontFaces, FontFamilies, FontTheme } from './FontFamilies';
import { Colors, ColorTheme } from './Colors';
import { Body1, Headline1, Headline2, Headline3, Headline4, Headline5, Headline6 } from './FontStyles';
import { BREAKPOINT_LG, BREAKPOINT_MD, DIMEN_BOXED_CONTENT_WIDTH } from './Breakpoints';

export const GlobalStyles = createGlobalStyle`
  ${FontFaces};
  ${FontTheme};
  ${ColorTheme};
  
  :root {
    --content-max-width: ${DIMEN_BOXED_CONTENT_WIDTH}px;
    --content-indent: 1rem;
    --gutter-width: 0.75rem;
    --nav-height: 4.8125rem;
    --nav-padding: 6.5rem;
    --page-grid: minmax(var(--content-indent), 1fr) minmax(0, var(--content-max-width)) minmax(var(--content-indent), 1fr);
    --content-spacing: clamp(1rem, 2.75vw, 3.5rem);

    // Gradients
    --gradient-1: linear-gradient(157.26deg, #D02C2C -12.42%, rgba(199, 51, 51, 0.0104167) 105.18%, rgba(173, 173, 173, 0) 105.19%);
    --gradient-normal: linear-gradient(152.56deg, #D02C2C 1.24%, rgba(209, 76, 76, 0) 101.84%);
    --gradient-highlight: linear-gradient(113deg, #FFB800 -1.84%, #FFEEC6 43.36%, #FFB800 103.99%);
    
    --vh: var(--fallback-vh, 1vh);

    @supports (height: 1lvh) {
      --vh: 1lvh;
    }

    @supports (height: 1dvh) {
      --vh: 1dvh;
    }

    ${BREAKPOINT_MD} {
      --nav-height: 5.75rem;
      --content-indent: 2rem;
      --gutter-width: 1.75rem;
    }

    ${BREAKPOINT_LG} {
      --content-indent: 3.5rem;
    }
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 16px;
  }

  body {
    background: ${Colors.background};
    font-family: ${FontFamilies.default};
    color: ${Colors.textDefault};
    overscroll-behavior: none;
    ${Body1};
    line-height: 1.5;

    scroll-behavior: smooth;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;


    &.no-scroll {
      width: 100%;
      position: fixed;
      overflow: hidden;
    }
  }

  section {
    transition: opacity 450ms ease-out 250ms;
  }

  html, body, #root {
    height: 100%;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${FontFamilies.headline};
  }

  h1 {
    ${Headline1};
  }

  h2 {
    ${Headline2};
  }

  h3 {
    ${Headline3};
  }

  h4 {
    ${Headline4};
  }

  h5 {
    ${Headline5};
  }

  h6 {
    ${Headline6};
  }

  p {
    ${Body1};
  }

  a {
    color: inherit;
    text-decoration: none;

    &[href=''] {
      display: contents;
      cursor: default;
    }

    @media (hover: hover) {
      :hover {
        text-decoration: underline;
      }
    }
  }

  textarea,
  select,
  button,
  input {
    font: inherit;
    color: inherit;
    appearance: none;
    background: transparent;
    border: 0;
    border-radius: 0;
    outline: 0;
    cursor: pointer;

    &:disabled {
      cursor: default;
    }

    &:focus {
      outline: 0;
    }
  }

  p,
  span,
  a {
    font: inherit;
  }

  svg {
    height: 1em;
    width: auto;
  }

  ul {
    list-style: none;
  }

  video,
  img {
    max-width: 100%;
  }

  picture {
    display: contents;
  }

  fieldset, form {
    appearance: none;
    border: 0;
  }

  *::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
  }

  *::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  }

  *::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
  }

  // Fix rendered qr code
  .walletconnect-qrcode__image {
    height: auto;
  }
`;

import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { FontFamilies } from '../../../../styles/FontFamilies';
import AlertIcon from '../../../icons/AlertIcon';
import { Button } from '../../../common/Button';
import { BREAKPOINT_MD } from '../../../../styles/Breakpoints';
import { AccountState, AddressState, AuthTokenState } from '../../../../states/AppData';
import { useMemo } from 'react';
import { useIsApiReady } from '../../../../services/ApiProvider';
import LinkHandler from '../../../common/LinkHandler';
import { Translations } from 'src/utils/Translations';
import { IMembershipType } from 'src/components/content/membership';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1.25rem;
  margin-block-end: 2rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;

const ShopDetailButton = styled(Button)`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 0.5rem;

  ${BREAKPOINT_MD} {
    width: auto;
    padding-inline: 2.5rem;
  }
`;

const Limit = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: ${FontFamilies.headline};
  font-size: 1.125rem;
  font-weight: 700;
  white-space: nowrap;

  svg {
    font-size: 1.5rem;
  }
`;

const ErrorNotification = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.625rem;
  color: var(--color-signal-yellow);
  border: 1px solid var(--color-signal-yellow);
  border-radius: 0.625rem;
  justify-content: flex-start;
  font-size: 0.875rem;
  text-align: start;
  background: var(--color-grey-900);

  :hover {
    text-decoration: none;
  }

  svg {
    font-size: 1.5rem;
    flex-shrink: 0;
  }

  @media (hover: hover) {
    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }
`;

const StyledLinkHandler = styled.a`
  :hover {
    text-decoration: none;
  }
`;

interface IBuyOrConnectButton {
  isPurchasable: boolean;
  label: string;
  maxAmount?: number;
  ownedItems?: number;
  requiredCategory?: IMembershipType;
  requiredMoonpoints: number;
  link?: string;
}

export default function BuyOrConnectButton(props: IBuyOrConnectButton) {
  const { isPurchasable, label, maxAmount, ownedItems, requiredCategory, requiredMoonpoints, link = undefined } = props;
  const addressState = useRecoilValue(AddressState);
  const accountState = useRecoilValue(AccountState);

  const isApiReady = useIsApiReady();
  const hasAddress = useMemo(() => Boolean(addressState?.id), [addressState]);

  const hasRequiredMembershipStatus = useMemo(() => {
    if (accountState?.account.level.includes('legacy')) {
      return true;
    }
    return requiredCategory ? accountState?.account.level.includes(requiredCategory) : true;
  }, [requiredCategory, accountState]);

  return (
    <Wrapper>
      <ButtonWrapper>
        {!link && <ShopDetailButton disabled={!isPurchasable || !hasAddress || !isApiReady}>{label}</ShopDetailButton>}
        {link && (
          <StyledLinkHandler href={link} target={'_blank'} rel={'noreferrer noopener'}>
            <Button theme={'secondary'} type={'button'}>
              {label}
            </Button>
          </StyledLinkHandler>
        )}
        {maxAmount && <Limit>Limit: {ownedItems === null ? maxAmount : ownedItems + '/' + maxAmount}</Limit>}
      </ButtonWrapper>

      {!hasRequiredMembershipStatus && (
        <ErrorNotification as={LinkHandler} to={'/membership'}>
          <AlertIcon />
          <p>
            This item is exclusive to {Translations.membership.types[requiredCategory!].title} members. <span>Upgrade your membership</span>{' '}
            to unlock access to more premium products.
          </p>
        </ErrorNotification>
      )}

      {accountState && requiredMoonpoints > accountState.account.points && (
        <ErrorNotification>
          <AlertIcon />
          <p>You currently don’t have enough Moonpoints for this item.</p>
        </ErrorNotification>
      )}

      {isApiReady && !hasAddress && (
        <ErrorNotification as={LinkHandler} to={'/profile/address'}>
          <AlertIcon />
          <p>
            You have to add your <span>address</span> before being able to purchase items from the shop.
          </p>
        </ErrorNotification>
      )}
    </Wrapper>
  );
}

import styled from 'styled-components';
import MembershipCard from './subcomponents/MembershipCard';
import { BREAKPOINT_XXL } from 'src/styles/Breakpoints';
import { useCallback, useEffect, useMemo, useState } from 'react';
import LoginModal from 'src/components/content/user/LoginModal';
import { useIsApiReady } from 'src/services/ApiProvider';
import AnimatedTabSwitch from 'src/components/common/AnimatedTabSwitch';
import { useSearchParams } from 'react-router-dom';
import TrustPilotBox from 'src/components/content/membership/subcomponents/TrustPilotBox';

const Wrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Headline = styled.h2`
  text-align: center;
  font-size: var(--font-size-headline-lg);
  font-weight: 600;
  text-transform: uppercase;
  margin-block-end: 1rem;

  ${BREAKPOINT_XXL} {
    margin-block-end: 1.75rem;
  }
`;

const Description = styled.p`
  text-align: center;
  font-size: var(--font-size-body-lg);
  max-width: 800px;
  align-self: center;
  margin-block-end: 2rem;
`;

const DesktopWrapper = styled.div`
  display: none;

  ${BREAKPOINT_XXL} {
    display: flex;
    align-self: center;
    margin-block-end: 3.75rem;
  }
`;

const ItemWrapper = styled.div<{ currentIndex: number }>`
  display: grid;
  grid-template-columns: minmax(200px, 400px);
  gap: 2.75rem 2rem;
  justify-content: center;

  ${BREAKPOINT_XXL} {
    grid-template-columns: repeat(auto-fill, minmax(200px, 400px));
  }

  > * {
    display: none;

    ${BREAKPOINT_XXL} {
      display: initial;
    }
  }

  > :nth-child(${({ currentIndex }) => currentIndex + 1}) {
    display: initial;
  }
`;

const StyledAnimatedTabSwitch = styled(AnimatedTabSwitch)`
  z-index: 1;
  margin-block-end: 1.25rem;
  position: sticky;
  top: 1rem;
  width: 100%;
  max-width: 25rem;
  align-self: center;

  div {
    width: 100%;
  }

  ${BREAKPOINT_XXL} {
    display: none;
  }
`;

export type IMembershipType = 'free' | 'subscription' | 'legacy';

export default function Membership() {
  const isApiReady = useIsApiReady();

  const [currentIndex, setCurrentIndex] = useState<number>(1);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [membershipPaymentInterval, setMembershipPaymentInterval] = useState<'30days' | '90days'>('30days');
  const [searchParams, setSearchParams] = useSearchParams();

  const subscriptionLink = useMemo(() => {
    if (membershipPaymentInterval === '30days') {
      return {
        href: process.env.REACT_APP_SUBSCRIPTION_LINK_30_DAYS || '',
        title: 'Subscription',
        target: '_blank',
      };
    } else {
      return {
        href: process.env.REACT_APP_SUBSCRIPTION_LINK_90_DAYS || '',
        title: 'Subscribe',
        target: '_blank',
      };
    }
  }, [membershipPaymentInterval]);

  useEffect(() => {
    document.body.classList.toggle('no-scroll', openLoginModal);

    return () => document.body.classList.remove('no-scroll');
  }, [openLoginModal]);

  useEffect(() => {
    const variant = searchParams.get('variant');
    if (variant === 'lite') {
      setCurrentIndex(0);
    } else if (variant === 'pro') {
      setCurrentIndex(1);
    } else if (variant === 'legacy') {
      setCurrentIndex(2);
    } else {
      setCurrentIndex(1);
    }
  }, [searchParams]);

  const handleTabClick = useCallback(
    (index: number) => {
      if (index === 0) {
        setSearchParams({ variant: 'lite' });
      } else if (index === 1) {
        setSearchParams({ variant: 'pro' });
      } else if (index === 2) {
        setSearchParams({ variant: 'legacy' });
      }
    },
    [setSearchParams],
  );

  return (
    <Wrapper>
      <Headline>Membership</Headline>

      <Description>Choose your path to the moon: Start for free or unlock exclusive benefits with a membership.</Description>

      <DesktopWrapper>
        <TrustPilotBox />
      </DesktopWrapper>

      <StyledAnimatedTabSwitch onClick={handleTabClick} currentIndex={currentIndex} items={['Lite', 'Pro', 'Legacy']} />

      <ItemWrapper currentIndex={currentIndex}>
        <MembershipCard type={'free'} onClick={() => setOpenLoginModal(true)} disabled={isApiReady} />

        <MembershipCard
          type={'subscription'}
          link={subscriptionLink}
          membershipPaymentInterval={membershipPaymentInterval}
          setMembershipPaymentInterval={setMembershipPaymentInterval}
        />

        <MembershipCard
          type={'legacy'}
          link={{ href: 'https://warehouse.mooncourt.xyz/products/mvp-pass', title: 'MVP Pass', target: '_blank' }}
        />
      </ItemWrapper>

      {openLoginModal && <LoginModal onClose={() => setOpenLoginModal(false)} />}
    </Wrapper>
  );
}

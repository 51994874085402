import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

export const MetaPixel = () => {
  const location = useLocation();

  if (process.env.REACT_APP_META_PIXEL) {
    ReactPixel.init(process.env.REACT_APP_META_PIXEL.toString());
  }

  useEffect(() => {
    ReactPixel.pageView();
    // ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
    // ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
    // ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
    // ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.
  }, [location]);

  return <></>;
};

import styled from 'styled-components';
import LinkHandler from 'src/components/common/LinkHandler';
import TrophyIcon from 'src/components/icons/TrophyIcon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RaffleProps } from 'src/services/shop';
import TicketIcon from 'src/components/icons/TicketIcon';
import { BREAKPOINT_LG, BREAKPOINT_MD } from 'src/styles/Breakpoints';
import { Glass } from 'src/styles/Custom';

const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.5s ease-in-out;
`;

const Wrapper = styled(LinkHandler)`
  ${Glass};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid #ffffff1a;

  @media (hover: hover) {
    :hover {
      text-decoration: none;
      box-shadow:
        var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000),
        0 0 15px rgba(155, 135, 245, 0.5);

      ${PreviewImage} {
        transform: scale(1.05);
        transform-origin: center;
      }
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  aspect-ratio: 1 / 1;
  overflow: hidden;
`;

const CategoryTag = styled.div`
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.125rem;
  border-radius: 100px;
  background-color: var(--color-primary-750);
  padding-inline: 0.625rem;
  padding-block: 0.125rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
`;

const Backdrop = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%;
  background-image: linear-gradient(to top, rgb(26 31 44 / 0.8), transparent);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 2.5% 4%;
`;

const Countdown = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 0.75rem;
  line-height: 1rem;
  gap: 0.25rem;

  span {
    white-space: nowrap;
  }

  ${BREAKPOINT_LG} {
    font-size: 0.875rem;
  }
`;

const CountdownLabel = styled.span`
  &::after {
    content: ':';
  }

  ${BREAKPOINT_MD} {
    &::after {
      content: ' in:';
    }
  }
`;

const Seconds = styled.span`
  display: inline-block;
  width: 1.5rem;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0.625rem;
  gap: 0.5rem;
  justify-content: space-between;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.p`
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 400;
  margin-block-end: auto;

  ${BREAKPOINT_LG} {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: flex-end;
`;

const Price = styled.p`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const PriceLabel = styled.p`
  color: #fff9;
  font-size: 10px;
`;

const ActionButton = styled.button`
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  padding-block: 0.5rem;
  padding-inline: 1rem;
  background-color: var(--color-primary-750);
  border-radius: 0.625rem;
  margin-block: 1rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;

  @media (hover: hover) {
    &:hover {
      background-color: rgb(209 199 250);
    }
  }

  &:disabled {
    background-color: rgb(155 135 245);
  }
`;

const MerchantInfo = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.25rem;
  color: #fff9;
  font-size: 0.75rem;
  line-height: 1rem;
`;

const MerchantLabel = styled.span`
  font-weight: 600;
`;

interface IRaffleCardProps extends RaffleProps {
  loadLazy?: boolean;
}

export default function RaffleCard(props: IRaffleCardProps) {
  const { id, item, startDate, endDate, category, loadLazy = false } = props;
  const { name, images, price, retailPrice, merchant, paymentForProductRequired } = item;

  const [countdownTime, setCountdownTime] = useState<{ time: string[]; label: string } | null>(null);

  const isFree = useMemo(() => !paymentForProductRequired || retailPrice === 0, [paymentForProductRequired, retailPrice]);

  const buttonLabel = useMemo(() => {
    if (endDate && new Date(endDate) < new Date()) {
      return 'Ended';
    } else if (startDate && new Date(startDate) > new Date()) {
      return 'Starting Soon';
    } else {
      return 'Buy Now';
    }
  }, [endDate, startDate]);

  const formatDate = useCallback((date: number) => {
    const dateObject = new Date(date);
    const days = dateObject.getUTCDate();
    const hours = dateObject.getUTCHours();
    const minutes = dateObject.getUTCMinutes();
    const seconds = dateObject.getUTCSeconds();
    return [`${days}d ${hours}h ${minutes}m `, `${seconds}s`];
  }, []);

  useEffect(() => {
    const currentTimestamp = new Date().getTime();
    const startTimestamp = new Date(startDate).getTime();
    const endTimestamp = new Date(endDate).getTime();

    if (startTimestamp > currentTimestamp) {
      setTimeout(() => setCountdownTime({ time: formatDate(startTimestamp - currentTimestamp), label: 'Starts' }), 1000);
    } else if (endTimestamp > currentTimestamp) {
      setTimeout(() => setCountdownTime({ time: formatDate(endTimestamp - currentTimestamp), label: 'Ends' }), 1000);
    } else {
      setCountdownTime(null);
    }
  }, [countdownTime, startDate, endDate, formatDate]);

  return (
    <Wrapper to={`/shop/raffles/${id}`} title={name}>
      <ImageWrapper>
        <PreviewImage src={images[0].image.url} alt={name} loading={loadLazy ? 'lazy' : 'eager'} />

        {countdownTime && (
          <Backdrop>
            <Countdown>
              <CountdownLabel>{countdownTime.label}</CountdownLabel>
              <span>
                {countdownTime.time[0]}
                <Seconds>{countdownTime.time[1]}</Seconds>
              </span>
            </Countdown>
          </Backdrop>
        )}

        <CategoryTag>
          <TrophyIcon />
          {category.label}
        </CategoryTag>
      </ImageWrapper>

      <Body>
        <Name>{name}</Name>

        <Bottom>
          <PriceWrapper>
            <Price>{price} MP</Price>
            <PriceLabel>per ticket</PriceLabel>
          </PriceWrapper>

          <PriceWrapper>
            <Price>{isFree ? <span style={{ color: 'var(--color-signal-green)' }}>FREE</span> : `${retailPrice.toFixed(2)} €`}</Price>
            <PriceLabel>after win</PriceLabel>
          </PriceWrapper>

          <ActionButton>
            <TicketIcon /> {buttonLabel}
          </ActionButton>

          <MerchantInfo>
            Sold by <MerchantLabel>{merchant?.name || 'Mooncourt'}</MerchantLabel>
          </MerchantInfo>
        </Bottom>
      </Body>
    </Wrapper>
  );
}

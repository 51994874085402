import { useApi } from './ApiProvider';

interface IGetIsEmailRegisteredResponse {
  registered: boolean;
}

interface ILoginProps {
  email: string;
  password: string;
}

interface ISignUpResponse {
  account: string;
  success: boolean;
}

interface ISignInResponse {
  email: string;
  id: string;
  token: string;
  confirmed: boolean;
  account?: string;
}

interface ICompleteSignUpProps {
  account: string;
  code: string;
}

interface ICompleteSignUpResponse {
  token: string;
  email: string;
  id: string;
}

export const useAccountApi = () => {
  const apiInstance = useApi();
  const getIsEmailRegistered = async (email: string) => {
    return await apiInstance.get<IGetIsEmailRegisteredResponse>(`/v2/account/email/check/${email}`, { validateStatus: () => true });
  };

  const signUp = async (data: ILoginProps) => {
    return (await apiInstance.post<ISignUpResponse>(`/v2/account/email/sign-up`, data)).data;
  };

  const signIn = async (data: ILoginProps) => {
    return await apiInstance.post<ISignInResponse>(
      `/v2/account/email/sign-in`,
      {},
      {
        validateStatus: () => true,
        headers: {
          Authorization: `Basic ${btoa(`${data.email}:${data.password}`)}`,
        },
      },
    );
  };

  const completeSignUp = async (data: ICompleteSignUpProps) => {
    return (await apiInstance.post<ICompleteSignUpResponse>(`/v2/account/email/sign-up/complete`, data)).data;
  };

  const requestNewOtp = async (email: string) => {
    return (await apiInstance.post('/v2/account/email/resend', { email })).data;
  };

  const resetPassword = async (email: string) => {
    return (await apiInstance.post('/v2/account/email/password/reset', { email })).data;
  };

  const completePasswordReset = async (email: string, code: number, password: string) => {
    return (await apiInstance.post('/v2/account/email/password/reset/complete', { email, code, password })).data;
  };

  const newsletterSignUp = async () => {
    return (await apiInstance.post('/v2/account/newsletter/sign-up')).data;
  };

  const newsletterOptIn = async (token: string) => {
    return (await apiInstance.post(`/v2/account/newsletter/opt-in/${token}`)).data;
  };

  const newsletterOptOut = async () => {
    return (await apiInstance.post('/v2/account/newsletter/opt-out')).data;
  };

  const hideNewsletterModal = async () => {
    return (await apiInstance.post('/v2/account/newsletter/hide')).data;
  };

  const toggleNotificationPreference = async (advertising: boolean) => {
    return (await apiInstance.patch('/v2/account/users/mail-preferences', { advertising })).data;
  };

  return {
    requestNewOtp,
    getIsEmailRegistered,
    signUp,
    signIn,
    completeSignUp,
    resetPassword,
    completePasswordReset,
    newsletterSignUp,
    newsletterOptIn,
    newsletterOptOut,
    hideNewsletterModal,
    toggleNotificationPreference,
  };
};

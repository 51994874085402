// General
import { FC, PropsWithChildren, useContext } from 'react';
import styled, { css } from 'styled-components';

// Helpers
import { WalletConnectTranslations } from '../layout/app/Layout';

// Components
import Close from '../icons/CloseIcon';

// Styles
import { BREAKPOINT_MD } from '../../styles/Breakpoints';

const StyledModal = styled.div`
  position: relative;
  width: MIN(870px, calc(100% - var(--padding-inline) * 2));
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(0.5rem);
  border-radius: 0.625rem;
  border: 2px solid rgba(255, 255, 255, 0.5);
  margin-block: auto;
  height: max-content;
  padding: 2rem;

  ${BREAKPOINT_MD} {
    padding-block: 3.75rem;
    padding-inline: 10rem;
  }
`;

const CloseButton = styled(Close)`
  color: var(--color-text-default);
  cursor: pointer;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  background: unset;
  border: 0;

  @media (hover: hover) {
    :hover {
      cursor: pointer;
      color: var(--color-primary-750);
    }
  }
`;

const Overlay = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  overflow-y: auto;
  overscroll-behavior: none;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(0.625rem);
  padding: var(--content-indent);

  @media (height: 100dvh) {
    height: 100dvh;
  }
`;

export interface IModalProps {
  className?: string;
  close?: () => void;
}

const Modal: FC<PropsWithChildren<IModalProps>> = (props) => {
  const { close, children, className } = props;
  const t = useContext(WalletConnectTranslations);

  return (
    <Overlay>
      <StyledModal className={className}>
        {close && (
          <CloseButton onClick={close} aria-label={t?.close}>
            <Close />
          </CloseButton>
        )}

        {children}
      </StyledModal>
    </Overlay>
  );
};

export default Modal;

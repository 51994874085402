import styled from 'styled-components';
import { DetailPageSubHeadline } from './DetailPageStyledComponents';
import Select from '../../../common/form/Select';
import { ShopItemVariant } from '../../../../services/shop';
import { useMemo } from 'react';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-block: 2rem;

  select {
    text-transform: capitalize;
  }
`;

const HeadlineWrapper = styled.div`
  display: flex;
  gap: 1.25rem;
  align-items: center;
`;

const Amount = styled.h6`
  font-size: 1.125rem;
  line-height: 1;
  font-weight: 700;
`;

interface IDetailPageVariantSelectProps {
  variants: ShopItemVariant[];
  disabled?: boolean;
}

export default function DetailPageVariantSelect(props: IDetailPageVariantSelectProps) {
  const { variants, disabled = false } = props;

  const formattedVariants = useMemo(() => {
    return variants
      .map((variant) => ({
        name: variant.attributes
          .map((attr) => attr.type + ': ' + attr.value)
          .join(' | ') /*+ (availableAmount ? ` - (${variant.availableAmount} left)` : '')*/,
        value: variant.id,
        disabled: variant.soldOut,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [variants]);

  return (
    <Wrapper>
      <HeadlineWrapper>
        <DetailPageSubHeadline>Variants</DetailPageSubHeadline>
      </HeadlineWrapper>

      <Select name={'variant'} items={formattedVariants} placeholder={'select size'} disabled={disabled} />
    </Wrapper>
  );
}

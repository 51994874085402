import { useMemo } from 'react';
import CloseIcon from 'src/components/icons/CloseIcon';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AccountState, LocalStorageState } from 'src/states/AppData';
import LinkHandler from 'src/components/common/LinkHandler';
import { BREAKPOINT_MD } from 'src/styles/Breakpoints';

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  background: var(--color-primary-750);
  margin-inline: calc(-1 * var(--content-spacing));
  padding-inline: var(--content-spacing);
  padding-block: 0.5rem;
`;

const Body = styled.div`
  display: flex;
  gap: 0.25rem 0.75rem;
  align-items: center;
  justify-content: center;
  margin-inline: auto;
  flex-wrap: wrap;
`;

const Text = styled.p`
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;

  ${BREAKPOINT_MD} {
    font-size: 14px;
  }
`;

const Button = styled(LinkHandler)`
  color: var(--color-text-default);
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  border-radius: 6px;
  border: 1px solid var(--color-text-default);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition: background 0.125s ease-in-out;
  padding: 4px 8px;

  @media (hover: hover) {
    :hover {
      text-decoration: none;
      background: rgba(0, 0, 0, 0.2);
    }
  }

  ${BREAKPOINT_MD} {
    padding: 4px 12px;
  }
`;

const CloseButton = styled.button`
  display: flex;
  transition: color 0.125s ease-in-out;

  @media (hover: hover) {
    :hover {
      color: rgba(0, 0, 0, 0.3);
    }
  }
`;

export default function SubscriptionRenewalBanner() {
  const accountState = useRecoilValue(AccountState);
  const [localStorageState, setLocalStorageState] = useRecoilState(LocalStorageState);

  const isVisible = useMemo(() => {
    if (!accountState?.subscription?.subscribedUtil) return false;
    const hoursOfMembershipLeft = Math.floor(
      (new Date(accountState.subscription.subscribedUtil).getTime() - new Date().getTime()) / (1000 * 60 * 60),
    );

    if (hoursOfMembershipLeft < 7 * 24 && hoursOfMembershipLeft > 0) {
      if (!localStorageState.subscriptionBannerShownDate) return true;

      const hoursRemindedBeforeExpiry = Math.floor(
        (new Date(accountState.subscription.subscribedUtil).getTime() - new Date(localStorageState.subscriptionBannerShownDate).getTime()) /
          (1000 * 60 * 60),
      );

      if (hoursOfMembershipLeft < 3 * 24 && hoursRemindedBeforeExpiry > 71) return true;
      else return hoursRemindedBeforeExpiry > 7 * 24;
    } else return false;
  }, [accountState, localStorageState]);

  const formattedDate = useMemo(() => {
    if (!accountState?.subscription?.subscribedUtil) return '';

    const timeDelta = new Date(accountState.subscription.subscribedUtil).getTime() - new Date().getTime();
    const days = Math.floor(timeDelta / (1000 * 60 * 60 * 24));

    if (days > 1) {
      return `${days} days`;
    } else if (days > 0) {
      return Math.floor(timeDelta / (1000 * 60 * 60)) + ' hours';
    }

    const hours = Math.floor(timeDelta / (1000 * 60 * 60));
    return `${hours} hours`;
  }, [accountState]);

  function handleClose() {
    setLocalStorageState((state) => ({ ...state, subscriptionBannerShownDate: new Date().toString() }));
  }

  if (!isVisible) return <></>;
  else
    return (
      <Wrapper>
        <Body>
          <Text>Your Membership expires in {formattedDate}!</Text>
          <Button to={process.env.REACT_APP_RESUBSCRIBE_LINK || ''} title={'Resubscribe'}>
            Resubscribe
          </Button>
        </Body>
        <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
      </Wrapper>
    );
}

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { BREAKPOINT_MD, DIMEN_BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { ShopItemCategory, ShopItemProps, RaffleProps, useShopApi } from '../../../services/shop';
import { Translations } from '../../../utils/Translations';
import { InternalStyledButton, Wrapper } from '../Container';
import ComingSoonCard, { IComingSoonItem } from '../cards/ComingSoonCard';
import RaffleCard from 'src/components/content/cards/RaffleCard';
import CardGrid from 'src/components/content/cards/CardGrid';

const HeaderWrapper = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column-reverse;
  gap: 2rem;

  ${BREAKPOINT_MD} {
    justify-content: space-between;
    flex-direction: row;
  }
`;

const ControlsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  align-items: center;
`;

const LevelButton = styled(InternalStyledButton)<{ active: boolean }>`
  ${({ active }) =>
    active &&
    css`
      border-color: var(--color-primary-750);
      color: var(--color-primary-750);
      pointer-events: none;
    `};
`;

export const Shop: FC = () => {
  const [raffles, setRaffles] = useState<RaffleProps[] | null>(null);
  const [categories, setCategories] = useState<ShopItemCategory[] | null>(null);
  const [currentCategory, setCurrentCategory] = useState<ShopItemCategory | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const shopApi = useShopApi();

  const sortedRaffles = useMemo(() => {
    if (!raffles) return [];

    const endInRaffles = raffles
      .filter(({ startDate, endDate }) => {
        if (startDate && new Date(startDate).getTime() > new Date().getTime()) {
          return false;
        } else {
          return endDate && new Date(endDate).getTime() > new Date().getTime();
        }
      })
      .sort((a, b) => new Date(a.endDate).getTime() - new Date(b.endDate).getTime());

    const startInRaffles = raffles
      .filter(({ startDate, endDate }) => {
        if (endDate && new Date(endDate).getTime() < new Date().getTime()) {
          return false;
        } else return startDate && new Date(startDate).getTime() > new Date().getTime();
      })
      .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

    const endedItems = raffles
      ?.filter(({ endDate }) => endDate && new Date(endDate).getTime() < new Date().getTime())
      .sort((a, b) => (new Date(a.endDate).getTime() - new Date(b.endDate).getTime() ? 1 : -1));

    return [...endInRaffles, ...startInRaffles, ...endedItems];
  }, [raffles]);

  const changeView = ({ matches }: MediaQueryListEvent) => {
    setIsMobile(matches);
  };

  useEffect(() => {
    const view = window.matchMedia(`(max-width: ${DIMEN_BREAKPOINT_MD}px)`);
    setIsMobile(view.matches);
    view.addEventListener('change', changeView);

    return () => {
      view.removeEventListener('change', changeView);
    };
  }, []);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const [categories, raffles] = await Promise.all([shopApi.getShopItemCategories(), shopApi.getRaffles()]);
      setRaffles(raffles.filter(({ item }: { item?: any }) => !item?.comingSoon));
      setCategories(((categories as ShopItemCategory[]) || [])?.sort((a, b) => (a?.label > b?.label ? 1 : -1)));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setCurrentCategory(null);
    }
  }, []);

  const fetchRaffles = async (category: ShopItemCategory | null = null) => {
    setLoading(true);
    try {
      const raffles = category ? await shopApi.getRafflesByCategory(category.id) : await shopApi.getRaffles();
      setRaffles(raffles.filter(({ item }: { item?: any }) => !item?.comingSoon));
    } catch (error) {
      console.log('ERROR - fetching raffles by category:', error);
      setRaffles(null);
    } finally {
      setLoading(false);
    }
  };

  const setShowAllItems = async () => {
    await fetchRaffles();
    setCurrentCategory(null);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchRaffles(currentCategory);
  }, [currentCategory]);

  return (
    <Wrapper>
      <HeaderWrapper>
        <ControlsWrapper>
          <LevelButton theme={'secondary'} active={currentCategory === null} onClick={() => setShowAllItems()}>
            All
          </LevelButton>

          {categories
            ?.sort((a: ShopItemCategory, b: ShopItemCategory) => (a.displayOrder < b.displayOrder ? -1 : 1))
            .map((category, index) => (
              <LevelButton
                key={index}
                theme={'secondary'}
                active={currentCategory === category}
                onClick={() => setCurrentCategory(category)}>
                {category.label}
              </LevelButton>
            ))}
        </ControlsWrapper>
      </HeaderWrapper>

      <CardGrid loading={loading} placeholder={{ ...Translations.shop.placeholder }}>
        {sortedRaffles.map((item, index) => (
          <RaffleCard key={index} {...item} loadLazy={isMobile ? index > 5 : index > 11} />
        ))}
      </CardGrid>
    </Wrapper>
  );
};
